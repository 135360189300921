import ToolDTO from "~/ts/models/dto/tools/ToolDTO";
import UpdateToolDTO from "~/ts/models/dto/tools/UpdateToolDTO";
import MappingDTO from "~/ts/models/dto/tools/MappingDTO";
import ManualSyncDTO from "~/ts/models/dto/tools/ManualSyncDTO";
import AuthInstance from "~/ts/interfaces/AuthInstance";
import { createFetch } from "~/ts/helpers/apiFetch";

export const useTools = (auth: AuthInstance = useAuth()) => {
  const apiFetch = createFetch();

  return {
    async getAll(): Promise<ToolDTO[]> {
      const response = await apiFetch("/tools", {
        auth,
      })
        .then(r => r.json());

      return response.tools;
    },

    async getToolById(id: string): Promise<ToolDTO> {
      return await apiFetch(`/tools/${id}`, {
        auth,
      })
        .then(r => r.json());
    },

    async getMappingsForTool(id: string): Promise<MappingDTO[]> {
      const response = await apiFetch(`/tools/${id}/mappings`, {
        auth
      })
        .then(r => r.json());

      return response.mappings;
    },

    async updateToolById(id: string, tool: UpdateToolDTO) {
      await apiFetch(`/tools/${id}`, {
        auth,
        method: "PATCH",
        jsonBody: tool,
      });
    },

    async updateMappingsForTool(id: string, mappings: MappingDTO[]) {
      const map: Record<string, Omit<MappingDTO, "key">> = {};

      for (const mapping of mappings) {
        map[mapping.key] = mapping;
      }

      await apiFetch(`/tools/${id}/mappings`, {
        auth,
        method: "PUT",
        jsonBody: map,
      });
    },

    async updateDefaultSortForTool(
      id: string,
      key: string | null,
      direction: "asc" | "desc"
    ) {
      await apiFetch(`/tools/${id}/default-sorting`, {
        auth,
        method: "PATCH",
        jsonBody: {
          default_sort_key: key,
          default_sort_direction: direction,
        },
      });
    },

    async syncManually(id: string): Promise<ManualSyncDTO> {
      return await apiFetch(`/tools/${id}/sync-now`, {
        auth,
        method: "PUT",
      })
        .then(r => r.json());
    },

    async deleteOrphanedTool(id: string) {
      await apiFetch(`/tools/${id}`, {
        auth,
        method: "DELETE",
      });
    },

    async relinkOrphanedTool(id: string, newId: string) {
      await apiFetch(`/tools/${id}/relink`, {
        auth,
        method: "PATCH",
        jsonBody: {
          new_id: newId,
        },
      });
    },
  }
};

